import { Image } from "antd";
import moment from "moment";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Company from "./company";
import Info from "./info";
import Links from "./links";
import Provide from "./provide";
import SocialBox from "./socialBox";

export default function Footer() {
  const { t } = useTranslation();
  const s = "common:main.";
  {
    t(s + "footer_info_1");
  }
  const data1 = {
    title: "moverii",
    data: [
      {
        txt: t(s + "about_us"),
        link: "/about-us",
      },
      {
        txt: t(s + "help_&_contact"),
        link: "/contact",
      },
      {
        txt: t(s + "travel_magazine"),
        link: "/blog",
      },
      {
        txt: t(s + "cancellation_policy"),
        link: "/content/cancellation",
      },
      {
        txt: t(s + "travel_protection"),
        link: "/travel-protection",
      },
    ],
  };
  const data2 = {
    title: "Reisen",
    data: [
      {
        txt: t(s + "yoga_retreats"),
        link: process.env.NEXT_PUBLIC_SITE_ADDRESS + "yoga-retreats",
      },
      {
        txt: t(s + "Surf_camps"),
        link: process.env.NEXT_PUBLIC_SITE_ADDRESS + "surfcamps",
      },
      {
        txt: t(s + "hiking_trips"),
        link: process.env.NEXT_PUBLIC_SITE_ADDRESS + "hiking-holidays",
      },
      {
        txt: t(s + "fitness_holidays"),
        link: process.env.NEXT_PUBLIC_SITE_ADDRESS + "fitnessreisen",
      },
      {
        txt: t(s + "martial_art_camps"),
        link: "/s/moverii/martial-arts",
      },
      {
        txt: t(s + "active_journeys"),
        link: process.env.NEXT_PUBLIC_SITE_ADDRESS + "active-holidays",
      },
    ],
  };
  return (
    <footer className="bg-darkBlue text-white">
      <div className="container md:max-w-[98%] lg:max-w-[95%]">
        <div
          className={
            "grid justify-items-stretch md:items-start md:grid-cols-2 lg:grid-cols-5 place-items-stretch  grid-cols-1 gap-12  pt-12 "
          }
        >
          <div className="col-span-2 px-4 ">
            <div className="flex space-y-7 flex-col md:items-start items-start pr-0 ">
              <div className={"flex flex-col"}>
                <div className="flex space-x-2 flex-row align-center self-start">
                  <div className="flex">Made with</div>
                  <div className="flex">
                    <Image
                      src="/static/images/heart.svg"
                      alt="Love"
                      width={"100%"}
                      height={"100%"}
                      loading="lazy"
                    />
                  </div>
                  <div className="flex">from</div>
                </div>
                <div className="flex mt-2 md:self-start self-center md:pl-0">
                  <Image
                    src="/static/images/logo.svg"
                    className="object-cover object-center"
                    alt="Moverii.de"
                    loading="lazy"
                    height={"100%"}
                    width={160}
                  />
                </div>
              </div>
              <div className="flex  self-start md:self-center ">
                <Info />
              </div>
              <div className={"flex md:self-start self-start md:px-0"}>
                <SocialBox />
              </div>
            </div>
          </div>
          <div className={"px-4"}>
            <Links items={data1} />
          </div>
          <div className={"px-4"}>
            <Links items={data2} />
          </div>
          <div
            className={
              "md:px-4 lg:px-0 col-span-2 md:col-span-full lg:col-span-1"
            }
          >
            <div className="lg:flex sm:px-5 lg:px-0 px-5 lg:flex-col lg:gap-7 md:mt-0 md:px-0 grid grid-cols-1  md:grid-cols-2 gap-7 md:gap-16 ">
              <Provide />
              <Company />
            </div>
          </div>
        </div>

        <div className="grid items-start md:grid-cols-5  sm:grid-cols-2  grid-cols-1 gap-12 mt-8 align-center py-4 border-t border-white/20">
          <div className={"flex col-span-4 divide-x"}>
            <div className={"flex px-5"}>
              <Link href={"/terms-and-conditions"}>
                <a
                  href={"/terms-and-conditions"}
                  className={"hover:text-orange"}
                >
                  {t("main.footer_menu1")}
                </a>
              </Link>
            </div>
            <div className={"flex px-5"}>
              <Link href={"/content/protection/impressum"}>
                <a
                  href={"/content/protection/impressum"}
                  className={"hover:text-orange"}
                >
                  {t("main.footer_menu2")}
                </a>
              </Link>
            </div>
            <div className={"flex px-5"}>
              <Link href={"/data-protection"}>
                <a href={"/data-protection"} className={"hover:text-orange"}>
                  {t("main.footer_menu3")}
                </a>
              </Link>
            </div>
          </div>

          <div className={"text-right pr-10"}>
            © moverii {moment().format("YYYY")}
          </div>
        </div>
      </div>
    </footer>
  );
}
