import { Image } from "antd";
import { useTranslation } from "react-i18next";

export default function Company() {
  const data = [
    {
      image: "/static/images/bank/paypal.png",
      alt: "paypal",
    },
    {
      image: "/static/images/bank/mastercard.png",
      alt: "mastercard",
    },
    {
      image: "/static/images/bank/visa.png",
      alt: "visa",
    },
    {
      image: "/static/images/bank/maestro.png",
      alt: "maestro",
    },
    {
      image: "/static/images/bank/amricanexp.png",
      alt: "amricanexp",
    },
    {
      image: "/static/images/bank/jcb.png",
      alt: "jcb",
    },
    {
      image: "/static/images/bank/discover.png",
      alt: "discover",
    },
    {
      image: "/static/images/bank/sofort.png",
      alt: "sofort",
    },
    {
      image: "/static/images/bank/klarna.png",
      alt: "klarna",
    },
    {
      image: "/static/images/bank/googlepay.png",
      alt: "googlepay",
    },
    {
      image: "/static/images/bank/applepay.png",
      alt: "applepay",
    },
    {
      image: "/static/images/bank/bancontact.png",
      alt: "bancontact",
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:px-0  md:self-start  text-left">
      <p className="font-bold text-lg mb-[8px]">{t("main.Sichere_Zahlung")}</p>
      <div className="!w-full grid grid-cols-4 md:w-1/2 gap-1 lg:mr-20 md:items-center justify-start align-center md:self-start self-center -ml-20 -mt-3 md:-ml-[3.5rem] lg:ml-0 lg:mt-1 scale-75 md:scale-[.7] lg:scale-100">
        {data.map((v, i) => (
          <div key={"icon" + i} className={"bg-white rounded-xl "}>
            <Image
              className="rounded-xl object-cover object-center"
              src={v.image}
              alt={v.alt + " icon"}
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
